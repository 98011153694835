<div class="dialog">
	@if (title$ | async; as title) {
		<header class="dialog-header">
			<h1 class="typography-bold">
				{{ title }}
			</h1>
			<div class="dialog-header__actions">
				@if (dialogData.isRequired) {
					<span class="dialog-header__indicator">
						<span class="dialog-header__indicator-asterisk">*</span>
						Required
					</span>
				}
				<button
					class="dialog-header__close-button"
					type="button"
					(click)="closeDialogWithResult(false)"
				>
					<mat-icon class="dialog-header__close-icon">close</mat-icon>
				</button>
			</div>
		</header>
	}

	<div
		[class.dialog__body]="includeBodyStyles"
		class="dialog__body_scrollable"
		[id]="dialogBodyId"
		cdkScrollable
	>
		<ng-content></ng-content>
	</div>
</div>
